<template>
  <div
    class="p-5 text-white text-right"
    style="background-color:var(--color-braun);"
  >
    <RouterLink
      to="/impressum"
      class="text-sm mr-5"
    >
      Impressum
    </RouterLink>
    <RouterLink
      to="/datenschutz"
      class="text-sm"
    >
      Datenschutz
    </RouterLink>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped lang="scss">

</style>
